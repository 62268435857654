.explorer-back-background{
    transition: 0.5s;
    transition-delay: 1s;
    opacity: 0;
    z-index: 0;
}
.explorer-back.active .explorer-back-background{
    transition: 0s;
    opacity: 1;
}
.back-character-layer{
    width: auto;
    height: 86%;
    left: -8.3rem;
}
.explorer-close-button {
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    position: absolute;
    top: 5.15%;
    left: 4.38%;
    z-index: 5;
    transform: rotateX(0deg);
  }
.back-content-holder{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 3rem ;
}
.back-bio-icon{
    max-width: 5rem;
    max-height: 5rem;
    margin-bottom: 1.2rem;
}
.back-title{
    font-family: 'poppins', sans-serif;
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 4rem;
    letter-spacing: 0em;
    text-align: center;
    
}
.back-subtitle{
    font-family: 'poppins', sans-serif;
    font-size: 	1.3rem;
    font-weight: 600;
    line-height: 2.15rem;
    letter-spacing: 0em;
    text-align: center;

}
.back-description{
    font-size: 1.2rem;
    line-height: 2rem;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: center;
}
.back-subtitle.bio-subtitle{
    margin-bottom: 1.3rem;
}
.align-left-container{
    justify-content: flex-start;
    align-items: flex-start;
}
.back-row{
    display: grid;
    grid-template-columns: 40% 10% 50%;
    grid-template-rows: auto;
    grid-template-areas: 
    "title . item";
    width: 100%;
}
.back-subtitle.row-title{
   grid-area: title;
   text-align: left;
}
.displayed-metadata{
    font-family: 'poppins', sans-serif;
    font-size: 1.3rem;
    line-height: 2.15rem;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    grid-area: item;
}
.mb-title{
    margin-bottom: 1.75rem;
}
.mid-line{
    background-color: #fff;
    width: 100%;
    height: 0.25rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
.base-kit-box{
    width: 100%;
    max-height: 30vh;
}