
html {
  font-size: calc(19.5px + 0.15vw);
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}
html, body {margin: 0; height: 100%; overflow: hidden}
p{
  margin: 0;
  font-family: 'poppins', sans-serif;
  color: #FFFFFF;
}
h2, h3, h4 {
  margin: 0;
  font-family: 'poppins-semibold', sans-serif;
  color: #FFFFFF;
}

@font-face {
  font-family: 'poppins';
  src: local('poppins'), url(./assets/fonts/Poppins.ttf) format('truetype');
}
@font-face {
  font-family: 'poppins-semibold';
  src: local('poppins-semibold'),
    url(./assets/fonts/Poppins-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'outage';
  src: local('outage'),
    url(./assets/fonts/Outage.woff) format('truetype');
}