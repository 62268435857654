.card-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    flex-direction: column;
    background-color: #2a4060e6;
    overflow: hidden;
    transform: rotateY(180deg);
}

.explorer-card-image-container {
    position: relative;
    margin: auto;
    height: 100%;
    width: 100%;
    pointer-events: none;
}

.explorer-pyramid_anim_container {
    z-index: 2;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    transform: translate3d(0, 0, 0);
}

.resizer-container {
    height: 100%;
    width: 100%;
    pointer-events: all;
}

.explorer-scope span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: center;
    transform-style: preserve-3d;
}

.explorer-scope span:first-child {
    transform: rotateY(calc(0deg)) translateZ(1px) rotateX(0deg);
}

.explorer-scope span:nth-child(2) {
    transform: rotateY(calc(0deg)) translateZ(0px) rotateX(0deg);
}

.explorer-scope {
    position: relative;
    transform-style: preserve-3d;
    animation: slid 5s linear infinite;
    transition: 1.5s all;
}

.explorer-scope span {
    overflow: hidden;
}

.explorer-front {
    z-index: 2;
}

.rare-element::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 20px solid #615f5f;
    z-index: 1;
}

.explorer-badge-img {
    position: absolute;
    width: 75px;
    height: 75px;
    bottom: 15px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    cursor: pointer;
    z-index: 5;
}

.explorer-flip-icon {
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.explorer-fullscreen-icon {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    cursor: pointer;
}

.explorer-back_card {
    animation: hideBack 2s ease-in-out normal;
    transition: all 1s;
    visibility: hidden;
}

.explorer-back_card.active {
    visibility: visible;
}

.explorer-card-back-root {
    height: 100%;
    transform: rotateY(180deg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: end;
    justify-content: flex-end;
    position: relative;
}

.explorer-card-icons-holder {
    position: absolute;
    inset: 0px;
    margin: auto;
    width: 100%;
    max-height: 40px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    z-index: 5;
}

.explorer-trait-container {
    position: absolute;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 0.75rem;
    top: 2.83%;
    right: 2.73%;
    z-index: 2;
}

.explorer-trait-holder {
    width: 4.5rem;
    height: 4.5rem;
    background-color: #2A4060;
    opacity: 0.8;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.explorertrait-holder:hover {
    background-color: #fc6405;
}

.explorer-trait-holder img {
    width: 3.2rem;
    height: 3.2rem;
}

.explorer-trait-card-root {
    width: 100%;
    height: 100%;
    transform: rotateY(180deg);
    -webkit-background-position: center;
    background-position: center;
    -webkit-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
}

.explorer-trait-card-content {
    width: 100%;
    height: 100%;
    background-color: #fc6405b3;
}

.explorer-trait-card-content-holder {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;

}

@keyframes shiningEffect1 {
    0% {

        left: -100%;
    }
    100% {

        left: 100%;
    }
}

@keyframes shiningEffect2 {
    0% {

        right: -100%;
    }
    100% {
        right: 100%;
    }
}

@keyframes hideBack {
    from {
        visibility: visible;
    }
    to {
        visibility: hidden;
    }
}

.explorer-flipped-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all .3s;
    pointer-events: none;
}

#effect-box {
    width: 100%;
    height: 100%;
    z-index: 3;
    transition: all .3s;
}

.explorer-flipped-datas-box {
    pointer-events: none;
    position: absolute;
    top: 37.26%;
    left: 8.57%;
    object-fit: cover;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    transition: all 0.3s;
    pointer-events: none;
}

.explorer-token-id-box {
    padding: 0.4rem;
    width: fit-content;
    height: fit-content;
    background: linear-gradient(to right, #FFFFFF 0.15rem, transparent 0.15rem) 0 0,
    linear-gradient(to right, #FFFFFF 0.15rem, transparent 0.15rem) 0 100%,
    linear-gradient(to left, #FFFFFF 0.15rem, transparent 0.15rem) 100% 0,
    linear-gradient(to left, #FFFFFF 0.15rem, transparent 0.15rem) 100% 100%,
    linear-gradient(to bottom, #FFFFFF 0.15rem, transparent 0.15rem) 0 0,
    linear-gradient(to bottom, #FFFFFF 0.15rem, transparent 0.15rem) 100% 0,
    linear-gradient(to top, #FFFFFF 0.15rem, transparent 0.15rem) 0 100%,
    linear-gradient(to top, #FFFFFF 0.15rem, transparent 0.15rem) 100% 100%;

    background-repeat: no-repeat;
    background-size: 1rem 1rem;
}

.explorer-token-id-box p {
    font-family: outage, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.7rem;
    line-height: 100%;
    max-width: 500px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #FFFFFF;
    opacity: 0.3;
}

.explorer-archetype-box {
    font-family: outage, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 100%;
    text-align: start;
    text-transform: uppercase;
    color: #FFFFFF;
}

.explorer-faction-box {
    font-family: outage, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 100%;
    text-transform: uppercase;
    color: #FF6A84;
    text-align: start;
}

.character-layer {
    width: auto;
    height: 86%;
    left: 2rem;
}

@media (min-width: 1701px) and (max-height: 1250px) {
    html {
        font-size: calc(12.8px + 0.12vw);
    }
}

@media (max-width: 1700px) {
    html {
        font-size: calc(13px + 0.13vw);
    }
}

@media (max-width: 1599px) {
    html {
        font-size: calc(12px + 0.45vw);
    }
}

@media (max-width: 1599px) and (max-height: 850px) {
    html {
        font-size: calc(12px + 0.2vw);
    }
}

@media (max-width: 1350px) {
    html {
        font-size: calc(12.5px + 0.5vw);
    }
}

@media (max-width: 1199px) {
    html {
        font-size: calc(12px + 0.6vw);
    }
}

@media (max-width: 1000px) {
    html {
        font-size: calc(11.8px + 0.7vw);
    }
}

@media (max-width: 799px) {
    html {
        font-size: calc(11.8px + 0.85vw);
    }
}

@media (max-width: 750px) {
    html {
        font-size: calc(12px + 0.9vw);
    }
}

@media (max-width: 699px) {
    html {
        font-size: calc(10.8px + 1vw);
    }
}

@media (max-width: 650px) {
    html {
        font-size: calc(10.4px + 1vw);
    }
}

@media (max-width: 599px) {
    html {
        font-size: calc(9px + 1vw);
    }
}

@media (max-width: 550px) {
    html {
        font-size: calc(8.5px + 1vw);
    }
}

@media (max-width: 499px) {
    html {
        font-size: calc(7.5px + 1vw);
    }
}

@media (max-width: 450px) {
    html {
        font-size: calc(7px + .9vw);
    }
}

@media (max-width: 399px) {
    html {
        font-size: calc(6.5px + 0.85vw);
    }
}

@media (max-width: 350px) {
    html {
        font-size: calc(6.0px + 0.8vw);
    }
}

@media (max-width: 299px) {
    html {
        font-size: calc(5.2px + 0.7vw);
    }
}

@media (min-width: 200px) and (max-width: 249px) {
    html {
        font-size: calc(4.2px + 0.85vw);
    }
}